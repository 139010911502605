import React from "react"
import BackgroundImage from "gatsby-background-image"

import EducationalHobbyProject from "./educationalHobbyProject"

const EducationalHobby = ({
  data: { heading, background_image_top, background_image_bottom, projects },
}) => {
  return (
    <section className="my-12">
      <BackgroundImage
        Tag="div"
        fluid={[
          background_image_top.childImageSharp.fluid,
          background_image_bottom.childImageSharp.fluid,
        ]}
        className="bg-dark bg-no-repeat bg-auto py-12"
        style={{
          backgroundPosition: "0 0, 0 100%",
          backgroundSize: "auto 60px",
        }}
      >
        <div className="container mx-auto px-2">
          <h2 className="text-3xl text-center text-white mx-auto font-medium">
            {heading}
          </h2>
        </div>
      </BackgroundImage>
      <div className="container mx-auto px-2">
        {projects.map((project, index, array) => {
          return (
            <EducationalHobbyProject
              data={project}
              index={index}
              array={array}
              key={index}
            />
          )
        })}
      </div>
    </section>
  )
}

export default EducationalHobby
