import React from "react"

import FreeCodeCampProject from "./freeCodeCampProject"

const FreeCodeCampProjectContainer = ({ projects }) => {
  if (projects) {
    return (
      <div className="bg-white shadow overflow-hidden sm:rounded-md mb-12">
        <ul>
          {projects.map((project, index) => {
            return (
              <FreeCodeCampProject
                key={index}
                project={project}
                index={index}
              />
            )
          })}
        </ul>
      </div>
    )
  }
  return null
}

export default FreeCodeCampProjectContainer
