import React from "react"
import BackgroundImage from "gatsby-background-image"
import FreeCodeCampSection from "./freeCodeCampSection"

const FreeCodeCamp = ({
  data: { heading, background_image_top, background_image_bottom, sections },
}) => {
  return (
    <section className="my-12">
      <BackgroundImage
        Tag="div"
        fluid={[
          background_image_top.childImageSharp.fluid,
          background_image_bottom.childImageSharp.fluid,
        ]}
        className="bg-dark bg-no-repeat bg-auto py-12"
        style={{
          backgroundPosition: "0 0, 0 100%",
          backgroundSize: "auto 60px",
        }}
      >
        <div className="container mx-auto px-2">
          <h2 className="text-3xl text-center font-semibold text-white mx-auto">
            {heading}
          </h2>
        </div>
      </BackgroundImage>
      <div className="container mx-auto px-2">
        {sections.map((section, index, array) => {
          return (
            <FreeCodeCampSection
              data={section}
              key={index}
              index={index}
              array={array}
            />
          )
        })}
      </div>
    </section>
  )
}

export default FreeCodeCamp
