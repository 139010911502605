import React, { useState } from "react"
import { useTransition, animated } from "react-spring"

import { toHtmlAst } from "../../utils/toHtmlAst"
import { renderAst } from "../../utils/renderAst"
import FreeCodeCampCodePen from "./freeCodeCampCodePen"
import RenderPrism from "../../utils/renderPrism"

const FreeCodeCampProject = ({
  project: { heading, codepen_pen_id, copy_markdown },
  index,
}) => {
  const [toggle, setToggle] = useState(false)

  const handleToggleOpen = () => {
    setToggle(!toggle)
  }

  const transition = useTransition(toggle, null, {
    from: { display: "none" },
    enter: { display: "block" },
    leave: { display: "none" },
  })

  return (
    <li key={index} className={`${index !== 0 && "border-t border-gray-200"}`}>
      <button
        className={`group w-full block hover:bg-gray-900 focus:outline-none ${
          toggle && "bg-gray-900"
        } transition duration-300 ease-in-out`}
        onClick={handleToggleOpen}
      >
        <div className="px-4 py-8 flex items-center sm:px-6">
          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
            <div>
              <h4
                className={`text-lg font-medium group-hover:text-white ${
                  toggle && "text-white"
                } truncate transition duration-300 ease-in-out`}
              >
                {heading}
              </h4>
            </div>
          </div>
          <div className="ml-5 flex-shrink-0">
            <svg
              className={`h-4 w-4  ${
                toggle ? "text-white" : "text-gray-400"
              } group-hover:text-white transition duration-300 ease-in-out`}
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      </button>
      {transition.map(
        ({ item, key, props }) =>
          item && (
            <animated.div
              className="px-4 py-8 flex flex-col items-center sm:px-6"
              key={key}
              style={props}
            >
              {codepen_pen_id && (
                <FreeCodeCampCodePen codepen_pen_id={codepen_pen_id} />
              )}
              <div className="text-2xl text-center mb-4">{heading}</div>
              {renderAst(
                {
                  p: ({ children }) => <p className="mb-4">{children}</p>,
                  ul: (props) => (
                    <ul className="list-disc mb-4 ml-8">{props.children}</ul>
                  ),
                  ol: (props) => (
                    <ol className="list-decimal mb-4 ml-8">{props.children}</ol>
                  ),
                  li: ({ children }) => <li className="">{children}</li>,
                  a: (props) => (
                    <a
                      className="text-blue-700 hover:underline hover:opacity-75 transition duration-300 ease-in-out"
                      href={props.href}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      {props.children}
                    </a>
                  ),
                  code: ({ children }) => <RenderPrism children={children} />,
                },
                toHtmlAst(copy_markdown)
              )}
            </animated.div>
          )
      )}
    </li>
  )
}

export default FreeCodeCampProject
