import React, { useEffect } from "react"
import Prism from "prismjs"

const RenderPrism = ({ children }) => {
  useEffect(() => {
    Prism.highlightAll()
  })
  return <code className="language-javascript">{children}</code>
}

export default RenderPrism
