import React from "react"
import FreeCodeCampProjectContainer from "./freeCodeCampProjectContainer"

const FreeCodeCampSection = ({
  data: { heading, certificate_text, certificate_link, projects },
  index,
  array,
}) => {
  return (
    <div>
      <div>
        <h3 className="text-2xl sm:text-3xl text-center font-medium mx-auto my-12">
          {heading} -{" "}
          <a
            className="text-blue-700 hover:underline hover:opacity-75 transition duration-300 ease-in-out"
            href={certificate_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {certificate_text}
          </a>
        </h3>
        <FreeCodeCampProjectContainer projects={projects} />
      </div>
      {index !== array.length - 1 && (
        <hr
          className="relative w-screen"
          style={{ marginLeft: "calc(-50vw + 50%)" }}
        />
      )}
    </div>
  )
}

export default FreeCodeCampSection
