import React from "react"
import Img from "gatsby-image/withIEPolyfill"

import { toHtmlAst } from "../../utils/toHtmlAst"
import { renderAst } from "../../utils/renderAst"

const EducationalHobbyProject = ({
  data: {
    button_label,
    heading,
    heading_icon,
    heading_icon_alt,
    image,
    image_alt,
    left_copy_markdown,
    project_link,
    right_copy_markdown,
  },
  index,
  array,
}) => {
  return (
    <div className="px-4">
      <div
        className={`flex flex-wrap -mx-4 my-24 items-center${
          index === 0 ? "" : index === 1 ? " flex-row-reverse" : ""
        }`}
      >
        <div className="w-full sm:w-2/3 px-4 mb-16 sm:mb-0">
          <a
            href={project_link}
            className="flex justify-center items-center mb-12 hover:underline transition duration-300 ease-in-out"
            target="_blank"
            rel="noreferrer noopener"
          >
            <Img
              className="mt-1"
              fixed={heading_icon.childImageSharp.fixed}
              alt={heading_icon_alt}
            />
            <h4 className="text-2xl font-semibold ml-2">{heading}</h4>
          </a>
          <div className="px-4">
            <div className="flex flex-wrap -mx-4">
              <div className="w-full sm:w-1/2 px-4 mb-8 sm:mb-0">
                {renderAst(
                  {
                    p: ({ children }) => <p className="mb-4">{children}</p>,
                  },
                  toHtmlAst(left_copy_markdown)
                )}
              </div>
              <div className="w-full sm:w-1/2 px-4">
                {renderAst(
                  {
                    p: ({ children }) => (
                      <p className="text-center mb-8">{children}</p>
                    ),
                    ul: ({ children }) => (
                      <ul className="list-disc list-inside">{children}</ul>
                    ),
                  },
                  toHtmlAst(right_copy_markdown)
                )}
              </div>
            </div>
          </div>
          <div className="text-center mt-12">
            <a
              href={project_link}
              className={`${
                heading === "Micro Blog App"
                  ? "hover:bg-project-blog-app text-project-blog-app border-project-blog-app "
                  : heading === "Recipe App"
                  ? "hover:bg-project-recipe-app text-project-recipe-app border-project-recipe-app "
                  : ""
              }cursor-pointer bg-transparent text-md sm:text-xl font-semibold hover:text-white py-2 px-4 border hover:border-transparent rounded transition duration-300 ease-in-out`}
              target="_blank"
              rel="noreferrer noopener"
            >
              {button_label}
            </a>
          </div>
        </div>
        <div className="w-full sm:w-1/3 flex justify-center">
          <a
            href={project_link}
            className=""
            target="_blank"
            rel="noreferrer noopener"
          >
            <Img fixed={image.childImageSharp.fixed} alt={image_alt} />
          </a>
        </div>
      </div>
      {index !== 0 ||
        (index !== array.length && (
          <hr
            className="relative w-screen"
            style={{ marginLeft: "calc(-50vw + 50%)" }}
          />
        ))}
    </div>
  )
}

export default EducationalHobbyProject
