import React from "react"
import Img from "gatsby-image/withIEPolyfill"

const ProjectsHero = ({ data: { heading, image, image_alt } }) => {
  return (
    <section className="container mx-auto flex flex-col items-center px-4 md:px-10">
      <Img
        className="mb-8"
        fixed={image.childImageSharp.fixed}
        alt={image_alt}
      />
      <h1 className="text-2xl md:text-4xl text-center leading-tight	mb-6 font-medium">
        {heading}
      </h1>
    </section>
  )
}

export default ProjectsHero
