import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProjectsHero from "../components/projects/projectsHero"
import EducationalHobby from "../components/projects/educationalHobby"
import FreeCodeCamp from "../components/projects/freeCodeCamp"
import ContactMe from "../components/banners/contactMe"

export const ProjectPageTemplate = ({
  data: {
    markdownRemark: {
      frontmatter: {
        slug,
        title,
        description,
        hero,
        educational_hobby,
        freecodecamp_projects,
      },
    },
  },
}) => {
  return (
    <Layout>
      <SEO title={title} description={description} pathname={slug} />
      <ProjectsHero data={hero} />
      <EducationalHobby data={educational_hobby} />
      <FreeCodeCamp data={freecodecamp_projects} />
      <ContactMe />
    </Layout>
  )
}

export default ProjectPageTemplate

export const pageQuery = graphql`
  query ProjectPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "projects-page" } }) {
      frontmatter {
        slug
        title
        description
        hero {
          heading
          image {
            childImageSharp {
              fixed(width: 200) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
          image_alt
        }
        educational_hobby {
          heading
          background_image_top {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          background_image_bottom {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          projects {
            heading
            heading_icon {
              childImageSharp {
                fixed(width: 16) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
            heading_icon_alt
            left_copy_markdown
            right_copy_markdown
            button_label
            project_link
            image {
              childImageSharp {
                fixed(width: 282) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
            image_alt
          }
        }
        freecodecamp_projects {
          heading
          background_image_top {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          background_image_bottom {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          sections {
            heading
            certificate_text
            certificate_link
            projects {
              heading
              codepen_pen_id
              copy_markdown
            }
          }
        }
      }
    }
  }
`
