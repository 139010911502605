import React from "react"

const FreeCodeCampCodePen = ({ codepen_pen_id }) => {
  return (
    <iframe
      height="265"
      className="mb-6"
      style={{
        width: "100%",
        height: "500px",
        boxSizing: "border-box",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "1em 0",
      }}
      scrolling="no"
      title="FCC: Drum Machine"
      src={`https://codepen.io/ttstauss/embed/${codepen_pen_id}?height=265&theme-id=dark&default-tab=result`}
      frameBorder="no"
      loading="lazy"
      allowtransparency="true"
      allowFullScreen={true}
    >
      See the Pen{" "}
      <a href={`https://codepen.io/ttstauss/pen/${codepen_pen_id}`}>
        FCC: Drum Machine
      </a>{" "}
      by Taylor Stauss (<a href="https://codepen.io/ttstauss">@ttstauss</a>) on{" "}
      <a href="https://codepen.io">CodePen</a>.
    </iframe>
  )
}

export default FreeCodeCampCodePen
